<template>
    <div class="roadshow_slider">
        <div class="roadshow_slider-nav">
            <div class="slide-item" v-for="region in regions" :key="region.id" :data-code="region.code">
                <div class="item_title">{{ region.town }}</div>
                <div class="item_date" v-if="!region.date_alt">{{ region.date }}</div>
                <div class="item_date" v-else>{{ region.date_alt }}</div>
            </div>
        </div>
        <div class="roadshow_slider-for">
            <div class="slide-item" v-for="region in regions" :key="region.id">
                <div class="item_block">
                    <div class="item_name">BE(er) DIGITAL</div>
                </div>
                <div class="item_block d-flex mb-3">
                    <div class="item_col">
                        <div class="item_title">{{ region.town }}</div>
                        <div class="item_subtitle" v-if="!region.date_alt">{{ region.date }}</div>
                        <div class="item_subtitle" v-else>{{ region.date_alt }}</div>
                    </div>
                    <div class="item_col">
                        <div class="item_title">35 € <small>bez&nbsp;DPH</small></div>
                        <div class="item_subtitle">(early birds vstupenky)</div>
                    </div>
                </div>
                <a :href="region.link" class="btn btn--white roadshow_btn" target="_blank">Chcem lístok</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            regions: Array
        },
        data: () => ({

        }),
        mounted: function () {
            this.roadshowMobileSlider();
        },
        methods: {
            roadshowMobileSlider: function () {
                let self = this;

                Vue.nextTick(function () {
                    const $roadshowSliderNav = $('.roadshow_slider-nav');
                    const $roadshowSliderFor = $('.roadshow_slider-for');

                    if ($roadshowSliderNav.hasClass('slick-initialized')) {
                        $roadshowSliderNav.slick('unslick');

                        return;
                    }

                    if ($roadshowSliderFor.hasClass('slick-initialized')) {
                        $roadshowSliderFor.slick('unslick');

                        return;
                    }

                    const roadshowSliderNavSlick = $roadshowSliderNav.slick({
                        infinite: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        dots: false,
                        asNavFor: $roadshowSliderFor
                    });

                    //Hide the Previous button.
                    $roadshowSliderNav.find('.slick-prev').fadeOut();

                    roadshowSliderNavSlick.on('afterChange', function (event, slick, currentSlide) {
                        //If we're on the first slide hide the Previous button and show the Next
                        if (currentSlide === 0) {
                            $roadshowSliderNav.find('.slick-prev').fadeOut();
                            $roadshowSliderNav.find('.slick-next').fadeIn();
                        }

                        //If we're on the last slide hide the Next button.
                        if (slick.slideCount === currentSlide + 1) {
                            $roadshowSliderNav.find('.slick-prev').fadeIn();
                            $roadshowSliderNav.find('.slick-next').fadeOut();
                        }

                        self.$emit('active-region', $(slick.$slides.get(currentSlide)).find('.slide-item').attr('data-code'));
                    });

                    $roadshowSliderFor.slick({
                        infinite: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: false,
                        fade: true,
                        asNavFor: $roadshowSliderNav
                    });
                })
            },
        }
    }
</script>