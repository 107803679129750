<template>
    <a href="#" class="mentor" v-if="mentor" @click.prevent="$emit('mentor-popup', mentor)">
        <div class="mentor_content">
            <div class="mentor_image" :style="{ 'background-image': 'url('+ mentor.about.image +')' }"></div>
            <div class="mentor_body">
                <div class="mentor_name">{{ mentor.about.name }}</div>
                <div class="mentor_position">{{ mentor.about.position }}</div>
                <div class="mentor_workshop" v-html="mentor.workshop.title"></div>
            </div>
        </div>
        <div class="mentor_about">Kto je {{ mentor.about.name | firstName }}?</div>
        <span class="mentor_link"><i class="icon icon-arrow-right"></i></span>
    </a>
</template>

<script>
    export default {
        props: {
            mentor: Object
        },
        data: () => ({

        }),
        filters: {
            firstName: function (fullName) {
                if (fullName) {
                    return fullName.split(' ')[0];
                }
            }
        }
    }
</script>