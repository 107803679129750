import initNav from './components/nav';
import initParticles from './components/particles';
import initForm from './components/form';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their 'basename'.
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./components/', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

import jsonFile from './data';

//Vue.component('mentor', require('./components/Mentor.vue').default);
//Vue.component('roadshow-mobile-slider', require('./components/RoadshowMobileSlider.vue').default);

const screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;

const app = new Vue({
    el: '#app',
    data: () => ({
        currentDate: moment().format('DD/MM/YYYY'),
        maxDate: "25/12/3020",
        selectedRegion: {},
        selectedMentor: {},
        regions: jsonFile.regions,
        mentors: jsonFile.mentors,
        showMentorPopup: false,
        mapTooltip: {
           active: false,
           regionCode: ''
        },
        transitionEffect: ''
    }),
    mounted: function () {
        this.transitionEffect = this.isMobile ? 'none' : 'fadeInUp';

        if (slugInURL) {
            scroll(0, 0);
            setTimeout(scroll(0, 0), 1);

            this.selectedRegion = this.regions.filter(region => {
                return region.townInURL === slugInURL;
            })[0];

            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $('#program-roadshow').offset().top - $('.main-nav').outerHeight()
                }, 1000);
            }, 300);
            return false;
        }

        if(!this.isMobile) {
            if (moment(this.currentDate, 'DD/MM/YYYY') <= moment(this.maxDate, 'DD/MM/YYYY')) {
                if (sessionStorage.getItem('location')) {
                    const location = JSON.parse(sessionStorage.getItem('location'));
                    this.selectRegionByLocation(location);
                } else {
                    geoip2.city(location => {
                        sessionStorage.setItem('location', JSON.stringify(location));
                        this.selectRegionByLocation(location);
                    }, error => {
                        console.error(`Error getting the location: ${JSON.stringify(error, undefined, 4)}`);
                    });
                }
            } else {
                this.selectedRegion = this.getRegionByCode('za');
            }
        }
        else {
            this.selectedRegion = this.sortedRegionsByDate[0];
        }
    },
    watch: {
        selectedRegion: function () {
            if (this.isMobile && this.filteredMentors(this.selectedRegion.code).length > 0) {
                this.filteredMentorsMobileSlider();
            }
        }
    },
    methods: {
        selectRegionByLocation: function (location) {
            if (!location.hasOwnProperty('subdivisions') || location.subdivisions.length === 0) {
                return false;
            }
            let regionName = location.subdivisions[0].names.en;
            regionName = regionName.toLowerCase();
            regionName = this.removeAccents(regionName);

            const region = this.regions.filter(region => {
                return region.townRegion === regionName;
            })[0];

            if (region) {
                this.selectedRegion = (moment(this.currentDate, 'DD/MM/YYYY') > moment(region.date, 'DD/MM/YYYY')) ? this.findRecommendedRegion(region) : region;
            }
            else {
                // only for Trenciansky kraj
                this.selectedRegion = this.getRegionByCode('za');
            }
        },
        getRoadshowTownByRegion: function (regionCode) {
            let result = '';
            this.regions.filter((region) => {
                if (region.code === regionCode) {
                    result = region.town;
                }
            });
            return result;
        },
        getRoadshowDateByRegion: function (regionCode) {
            let result = '';
            this.regions.filter((region) => {
                if (region.code === regionCode) {
                    result = region.date_alt ? region.date_alt : region.date;
                }
            });
            return result;
        },
        getRegionByCode: function (regionCode) {
            return this.regions.filter((region) => {
                return region.code === regionCode;
            })[0];
        },
        findRecommendedRegion: function (region) {
            let otherRegion = {};
            let code = region.recommendedCode;
            do {
                otherRegion = this.getRegionByCode(code);
                code = otherRegion.recommendedCode;
            }
            while (moment(this.currentDate, 'DD/MM/YYYY') > moment(otherRegion.date, 'DD/MM/YYYY'));

            return otherRegion;
        },
        chooseRegion: function (event) {
            const regionCode = event.currentTarget.id;
            if (regionCode) {
                this.setSelectedRegionByCode(regionCode);
            }
        },
        setSelectedRegionByCode: function (regionCode) {
            this.selectedRegion = this.getRegionByCode(regionCode);
        },
        filteredMentors: function (regionCode) {
            return this.mentors.filter((mentor) => {
                return mentor.regionCode === regionCode;
            });
        },
        filteredMentorsMobileSlider: function () {
            const $mentorsSlider = $('.mentors_slider');

            if ($mentorsSlider.hasClass('slick-initialized')) {
                $mentorsSlider.slick('unslick');
            }
            setTimeout(function () {
                Vue.nextTick(function () {
                    $mentorsSlider.slick({
                        infinite: false,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true,
                        responsive: [
                            {
                                breakpoint: 650,
                                settings: {
                                    slidesToShow: 1
                                }
                            }
                        ]
                    });
                })
            }, 100);
        },
        openMentorPopup: function (mentor) {
            this.showMentorPopup = true;
            this.selectedMentor = mentor;
            document.body.classList.toggle('overflow-hidden');
        },
        closeMentorPopup: function () {
            this.showMentorPopup = false;
            this.selectedMentor = {};
            document.body.classList.toggle('overflow-hidden');
        },
        showMapTooltip: function (event) {
            const regionCode = event.currentTarget.id;
            const regionElement = $('#'+regionCode);

            if (typeof (regionCode) == 'string') {
                this.mapTooltip.active = true;
                this.mapTooltip.regionCode = regionCode;

                Vue.nextTick(function () {
                    let tooltip = document.getElementById('map-tooltip');
                    if (tooltip) {
                        if (regionElement.hasClass('active')) {
                            tooltip.style.left = regionElement.position().left - 25 + 'px';
                            tooltip.style.top = regionElement.position().top - 100 + 'px';
                        }
                        else {
                            tooltip.style.left = regionElement.position().left - 29 + 'px';
                            tooltip.style.top = regionElement.position().top - 96 + 'px';
                        }
                    }
                });
            }
        },
        hideMapTooltip: function () {
            this.mapTooltip.active = false;
            this.mapTooltip.regionCode = '';
        },
        removeAccents(str_accents) {
            let strAccents = str_accents.split('');
            let strAccentsOut = new Array();
            let strAccentsLen = strAccents.length;
            const accents =    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñČčŠšŤťŸÿýŽž";
            const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnCcSsTtYyyZz";
            for (var y = 0; y < strAccentsLen; y++) {
                if (accents.indexOf(strAccents[y]) != -1) {
                    strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
                } else
                    strAccentsOut[y] = strAccents[y];
            }
            strAccentsOut = strAccentsOut.join('');

            return strAccentsOut;
        }
    },
    computed: {
        isMobile: function () {
            return screenWidth <= 768;
        },
        sortedRegionsByDate: function () {
            function compare(a, b) {
                if (moment(a.date, 'DD/MM/YYYY') < moment(b.date, 'DD/MM/YYYY'))
                    return -1;
                if (moment(a.date, 'DD/MM/YYYY') > moment(b.date, 'DD/MM/YYYY'))
                    return 1;
                return 0;
            }

            return this.regions.sort(compare);
        }
    },
    filters: {
        dateFormat: function (value) {
            if (value) {
                return moment(value, 'DD/MM/YYYY').format('D. M.');
            }
        }
    }
});

const $aboutSlider = $('.about_slider');

function initSlick() {
    const slickOptions = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true
    };

    if (!$aboutSlider.hasClass('slick-initialized')) {
        $aboutSlider.slick(slickOptions);
    }
}

function destroySlick() {
    [$aboutSlider].forEach((item, index) => {
        if (item.hasClass('slick-initialized')) {
            item.slick('unslick');
        }
    });
}

/* Slick needs no get Reinitialized on window Resize after it was destroyed */
$(window).on('load resize orientationchange', () => {
    if (screenWidth > 768) {
        destroySlick();
    } else {
        initSlick();
    }
});

$(() => {
    initNav();
    initParticles();
    initForm();
    initHandlers();
});

const initHandlers = function initHandlers() {
    $('.js-scroll-down').on('click', function () {
        let mainNavHeight = $('.main-nav').outerHeight();

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            const $link = $(this);
            let target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                let scrollTop = target.offset().top;

                if ($link.hash !== '#uvod') {
                    scrollTop = scrollTop - mainNavHeight;
                }

                $('html, body').stop().animate({
                    scrollTop: scrollTop
                }, 1000);
                return false;
            }
        }
    });
};
