<template>
    <div id="map-tooltip" class="map-tooltip">
        <div class="tooltip_speakers">
            <div class="tooltip_speaker" v-for="mentor in mentors" :key="mentor.id">{{ mentor.about.name }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            mentors: Array
        },
        data: () => ({

        })
    }
</script>