<template>
    <transition name="fade">
        <div id="mentor-popup" class="popup" v-if="openPopup">
            <div class="container popup_container">
                <div class="popup_content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="popup_inner popup_inner--left">
                                <div class="popup-mentor_image">
                                    <img :src="mentor.about.image" :alt="mentor.about.name">
                                </div>
                                <a href="#" class="popup_close popup_close--mobile d-block d-lg-none" @click.prevent="$emit('close-popup')"><i class="icon"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6 d-lg-flex align-items-lg-center">
                            <div class="popup_inner popup_inner--right">
                                <div class="popup-mentor">
                                    <h2 class="popup-mentor_caption">
                                        <span class="popup-mentor_name">{{ mentor.about.name }}</span> | <span class="popup-mentor_company">{{ mentor.about.company }}</span>
                                    </h2>
                                    <div class="popup-mentor_position">{{ mentor.about.position }}</div>
                                    <div class="popup_text popup-mentor_bio">
                                        <p v-for="bioText in mentor.about.bio">{{ bioText }}</p>
                                    </div>
                                </div>
                                <div class="popup-workshop">
                                    <h3 class="popup-workshop_title" v-html="mentor.workshop.title"></h3>
                                    <div class="popup_text popup-workshop_description">
                                        <template v-if="mentor.about.name != 'Peter Šebo'">
                                            <p v-for="descriptionText in mentor.workshop.description">{{ descriptionText }}</p>
                                        </template>
                                        <template v-else>
                                            <ul>
                                                <li v-for="descriptionText in mentor.workshop.description">{{ descriptionText }}</li>
                                            </ul>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="#" class="popup_close d-none d-lg-block" @click.prevent="$emit('close-popup')"><i class="icon"></i></a>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            openPopup: false,
            mentor: Object
        },
        data: () => ({

        }),
        mounted() {
            // Close modal with 'esc' key
            document.addEventListener("keydown", (e) => {
                if (e.keyCode == 27) {
                    this.$emit('close-popup');
                }
            });
        },
    }
</script>