export default function() {
    let $formValidator = $('#newsletter_form').validate({
        rules: {
            som: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            agree: {
                required: true
            }
        },
        messages: {
            som: "Toto pole je povinné.",
            email: {
                required: "Toto pole je povinné.",
                email: "Zadaná emailová adresa je neplatná."
            },
            agree: "Toto pole je povinné."
        },
        errorPlacement: function (error, element) {
            if (element.attr('name') == 'som' || element.attr('name') == 'agree') {
                error.insertAfter(element.closest('.require-group'));
            } else {
                error.insertAfter(element);
            }

        },
        submitHandler: function (form) {
            const $form = $(form);
            const action = $form.attr('action');
            let data = new FormData($form[0]);

            const $afterState = $form.find('.form-after-state .state-text');
            const $afterSuccessState = $form.find('.form-after-state .success-state');
            const $afterExistsState = $form.find('.form-after-state .exists-state');
            const $afterErrorState = $form.find('.form-after-state .error-state');

            axios.post(action, data)
                .then(function (response) {
                    $afterState.hide();
                    if (response.data.status === 'success') {
                        if ($afterSuccessState.length) {
                            $form[0].reset();
                            $afterSuccessState.fadeIn();
                        }
                    }
                    else if (response.data.status === 'exists') {
                        if ($afterExistsState.length) {
                            $afterExistsState.fadeIn();
                        }
                    }
                    else {
                        if ($afterErrorState.length) {
                            $afterErrorState.fadeIn();
                        }
                    }
                })
                .catch(function (error) {
                    console.log('Form error.');
                });
        }
    });
}