function onScroll() {
    const $document = $(document);
    const $body = $('body');
    const $hamburger = $('#hamburger');
    const $mainNav = $('.main-nav');
    const $mainNavItems = $mainNav.find('.main-nav_items');
    const mobileNavHeight = 80;

    if ($document.scrollTop() >= mobileNavHeight) {
        $mainNav.addClass('js-scroll-down');
    } else {
        $mainNav.removeClass('js-scroll-down');

        if ($body.hasClass('js-menu-opened') ) {
            $mainNavItems.fadeOut('fast', function () {
                $hamburger.removeClass('active');
                $body.removeClass('js-menu-opened');
            });
        }
    }
}

function onHamburgerClick() {
    const $body = $('body');
    const $hamburger = $('#hamburger');
    const $document = $(document);
    const $mainNav = $('.main-nav');
    const $mainNavItems = $mainNav.find('.main-nav_items');
    const mobileNavHeight = 80;

    $hamburger.toggleClass('active');
    if ($body.hasClass('js-menu-opened') ) {
        $mainNavItems.slideToggle('fast', function () {
            $body.toggleClass('js-menu-opened');
        });
    }
    else {
        $mainNavItems.slideToggle();
        $body.toggleClass('js-menu-opened');
    }
    if ($document.scrollTop() <= mobileNavHeight) {
        $mainNav.toggleClass('js-scroll-down');
    }
}

function scrollLinks() {
    const $body = $('body');
    const $hamburger = $('#hamburger');
    const $mainNav = $('.main-nav');
    const $mainNavItems = $mainNav.find('.main-nav_items');
    const screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;

    let mainNavHeight = $mainNav.outerHeight();

    if (screenWidth <= 991 ) {
        $hamburger.removeClass('active');
        $body.removeClass('js-menu-opened');
        $mainNavItems.fadeOut();
    }

    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        const $link = $(this);
        let target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            let scrollTop = target.offset().top;

            if ($link.hash !== '#uvod') {
                scrollTop = scrollTop - mainNavHeight;
            }

            $('html, body').stop().animate({
                scrollTop: scrollTop
            }, 1000);
            return false;
        }
    }
}

function scrollOnPageLoad() {
    if (location.hash) scroll(0, 0);
    setTimeout(scroll(0, 0), 1);

    const target = $(location.hash);
    const $mainNav = $('.main-nav');
    let mainNavHeight = $mainNav.outerHeight();

    if (target.length) {
        setTimeout(function() {
            let scrollTop = target.offset().top;

            $('html,body').stop().animate({
                scrollTop: scrollTop - mainNavHeight
            }, 1000);
            return false;
        }, 1);
    }
}

export default function () {
    $(document).on('scroll', onScroll);
    $('#hamburger').on('click', onHamburgerClick);
    $('.js-scroll-down').on('click', scrollLinks);
    if (location.hash) scrollOnPageLoad();
}